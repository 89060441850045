import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from "./components/NavScrollTop";
import HomeTwo from "./pages/HomeTwo";
import Work from "./pages/Work";
import WorkDetails from "./pages/WorkDetails";
import BlogGrid from "./pages/BlogGrid";
import BlogClassic from "./pages/BlogClassic";
import BlogDetails from "./pages/BlogDetails";
import JciCorporate from "./pages/JciCorporate";
import CorporateDetails from "./pages/CorporateDetails";
import Service from "./pages/Service";

// CSS File Here
import "aos/dist/aos.css";
import "react-modal-video/css/modal-video.min.css";
import "./assets/scss/style.min.css";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
  }, []);

  return (
    <Router>
      <NavScrollTop>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={HomeTwo}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home"}`}
            component={HomeTwo}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/төсөл-хөтөлбөр"}`}
            component={Work}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/work-details/:id"}`}
            component={WorkDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/jci-монгол-байгууллага"}`}
            component={BlogGrid}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/мэдээ-мэдээлэл"}`}
            component={BlogClassic}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}
            component={BlogDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/jci-corporate"}`}
            component={JciCorporate}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/corporate-details/:id"}`}
            component={CorporateDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/гишүүдийн-aпп"}`}
            component={Service}
          />
        </Switch>
      </NavScrollTop>
    </Router>
  );
}

export default App;
